






















import { Component, Mixins } from 'vue-property-decorator'

import { Inject } from '../../../support'
import { AllowedImageRatio, AllowedImageWidth, ISiteService, SiteServiceType } from '../../../contexts'
import { StructureConfigurable } from '../../../support/mixins'

import { AbstractModuleUi } from '../../abstract/ui'

import { SpecialHeroModule } from '../SpecialHero.contracts'
import {
  SPECIAL_HERO_COMPONENT_KEY,
  SPECIAL_HERO_DEFAULT_CONFIG
} from '../SpecialHero.config'
import { toImageProps } from '../../../front/shared/support'

/**
 * Container component for the `SpecialHeroModuleUi`.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<SpecialHeroModuleUi>({
  name: 'SpecialHeroModuleUi',
  components: {},
  created (): void {
    this.config = this.getComponentConfig(
      SPECIAL_HERO_COMPONENT_KEY,
      SPECIAL_HERO_DEFAULT_CONFIG
    )
  }
})
export class SpecialHeroModuleUi extends Mixins<AbstractModuleUi<SpecialHeroModule>, StructureConfigurable>(
  AbstractModuleUi,
  StructureConfigurable
) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  /**
   * Articles teaser content
   */
  public get items () {
    if (!this.content.items?.length) {
      return
    }

    return this.content.items
  }

  public get hasCTA (): boolean {
    return this.getConfigProperty<boolean>('hasCTA')
  }

  public get ctaLink (): string {
    return this.siteService.getProperty('blogUrl') as unknown as string
  }

  /**
   * Special hero heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  /**
   * Special hero background
   */
  public get backgroundImg () {
    if (!this.content.background) {
      return
    }

    return toImageProps(this.content.background, AllowedImageRatio.Original, AllowedImageWidth.FullWidth)
  }
}

export default SpecialHeroModuleUi
